import { Link, navigate } from "gatsby";
import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ConfigContext from '../context/ConfigProvider';

// Icons
import Menu from "../icons/menu";

// Menu Icons
import ClothingIcon from "../icons/klader.svg";
import OuterWearIcon from "../icons/outerwear.svg";
import WorkWearIcon from "../icons/work-wear.svg";
import SportsWearIcon from "../icons/sports-wear.svg";
import PrintOwnIcon from "../icons/egna-plagg.svg";
import EventIcon from "../icons/event.svg";
import GiveawayIcon from "../icons/give-away.svg";
import BottleIcon from "../icons/flaskor.svg";
import HomeIcon from "../icons/hem.svg";
import KidsWearIcon from "../icons/kids-wear.svg";
import ToteBagIcon from "../icons/totebag.svg";
import ElectronicsIcon from "../icons/teknik.svg";
import PensIcon from "../icons/pennor.svg";
import OfficeIcon from "../icons/kontor.svg";
import PlayingCardIcon from "../icons/spelkort.svg";
import "../styles/new-navigation.scss";

export function Navigation({ location, openMobileMenu, setOpenMobileMenu }) {
  const [openNav, setOpenNav] = React.useState(false);
  const [activeMenu, setActiveMenu] = React.useState(25);
  const { configData } = React.useContext(ConfigContext);

  React.useEffect(() => {
    if (!openMobileMenu && openNav) {
      setOpenNav(false);
      setOpenMobileMenu(false);
    } else setOpenNav(openMobileMenu);
  }, [openMobileMenu]);

  React.useEffect(() => {
    setOpenNav(false);
  }, [location]);

  return (
    <>
      <nav className="navStyle nav">
        <a
          className={"navLink"}
          onClick={() => { setOpenNav(!openNav); setActiveMenu(25); }}
        >
          <div className="icon">
            <Menu />
          </div>
          <b>Kategorier för tryck</b>
        </a>
        <div className="section">
          <Link
            key="Tryck-pa-eget"
            className={"navLink"}
            to="/tryck-pa-eget-plagg"
            title={"Gå till Tryck på Egna Plagg"}
            onClick={() => setOpenNav(false)}
          >
            <b>Tryck på egna plagg</b>
          </Link>
          <Link
            key="Kontakta-oss"
            className={"navLink"}
            to="/kontakta-oss/"
            title={"Gå till Kontakta Oss"}
            onClick={() => setOpenNav(false)}
          >
            <b>Kontakta oss</b>
          </Link>
        </div>
      </nav>
      {openNav ? (
        <div id="openMenu">
          <div className="menuWrapper">
            <div className="innerMenu">
              <p><b>Tryck på</b></p>
              {configData.categoryNav.map(category => (
                <div
                  key={category.bigcommerce_id}
                  className={category.bigcommerce_id === activeMenu ? "line active" : "line"}
                  onClick={() => {
                    if (category.bigcommerce_id === activeMenu || category.childrens.length === 0) {
                      navigate(category.custom_url.url);
                      setActiveMenu(false);
                      setOpenNav(false);
                    } else {
                      setActiveMenu(category.bigcommerce_id);
                    }
                  }}
                >
                  <div className="icon">
                    <img src={Icons[category.bigcommerce_id]} alt={category.name} />
                  </div>
                  <p>{category.name}</p>
                </div>
              ))}
              <div className="line link extra" onClick={() => setOpenNav(false)}>
                <div className="icon">
                  <img src={Icons["print-own"]} alt="Print Own Icon" />
                </div>
                <Link to={"/tryck-pa-eget-plagg/"} title={"Gå till Tryck på egna plagg"}>
                  Tryck på egna plagg
                </Link>
              </div>
            </div>
            <div className="selectedMenu">
              <div className="title"><h3>Kategorier</h3></div>
              {configData.categoryNav.filter(category => category.bigcommerce_id === activeMenu)[0]?.childrens?.map(category => {
                const image = getImage(category.localImage);
                return (
                  <Link
                    key={category.bigcommerce_id}
                    to={category.custom_url.url}
                    title={`Gå till ${category.name} med tryck`}
                    className="menuCard"
                    onClick={() => setOpenNav(false)}
                  >
                    <div className="image">
                      {image
                        ? <GatsbyImage image={image} title={`${category.name} med tryck`} alt={`${category.name} med tryck`} />
                        : category.image_url
                          ? <img src={category.image_url} title={`${category.name} med tryck`} alt={`${category.name} med tryck`} />
                          : null}
                    </div>
                    <div className="text">
                      {category.name}
                    </div>
                  </Link>
                );
              })}
              {/* <div className="title"><h3>Bästsäljare</h3></div> */}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

const Icons = {
  "print-own": PrintOwnIcon,
  25: ClothingIcon,
  126: OuterWearIcon,
  37: SportsWearIcon,
  40: WorkWearIcon,
  79: KidsWearIcon,
  52: ToteBagIcon,
  154: BottleIcon,
  42: OfficeIcon,
  155: PensIcon,
  145: HomeIcon,
  106: ElectronicsIcon,
  62: GiveawayIcon,
  92: EventIcon,
  133: PlayingCardIcon
};
