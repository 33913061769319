function storeUserInformation(type, infoType, value) {
    try {
        // Push the event
        if (type === "set") window.localStorage.setItem(`attitude-${infoType}`, value)
        else if (type === "get") return window.localStorage.getItem(`attitude-${infoType}`)
    }
    catch (err) { console.log(err) }
}

function dataLayerPush(eventName, data, eCommerce) {
    try {
        // Initiate Data Layer
        window.dataLayer = window.dataLayer || [];
        if (!eventName) throw ("DataLayer — No event name being sent")
        if (!data) throw ("DataLayer — No data being sent")
        // Clear eCommerce on new eCommerce event
        if (eCommerce) {
            window.dataLayer.push({ ecommerce: null })
            window.dataLayer.push({
                event: eventName,
                ecommerce: { ...data },
                ...data.customer
            })
        }
        // Else push the data
        else window.dataLayer.push({
            event: eventName,
            ...data
        })
    }
    catch (err) { console.log(err) }
}

function cartEcommerceObject(cart) {
    try {
        const items = cart.lineItems.physical_items.map((item, i) => {
            const productName = item.name.split(" - ")[0]
            return {
                item_id: item.product_id,
                item_name: productName.length > 0 ? productName : item.name,
                sku: item.sku,
                affiliation: "Attitude.se",
                index: i + 1,
                // item_category: "Apparel",
                // item_category2: "Adult",
                // item_category3: "Shirts",
                // item_category4: "Crew",
                // item_category5: "Short sleeve",
                item_variant: item.variant_id ? item.variant_id : false,
                price: item.list_price,
                quantity: item.quantity
            }
        })
        return {
            currency: "SEK",
            value: cart.totalAmount,
            items: items
        }
    }
    catch (err) { console.log(err) }
}

function lineItemEcommerceObject(productMod, brands) {
    try {
        const productId = productMod.product.bigcommerce_id
        const productName = productMod.product.name
        const productPrice = productMod.pricePerItem
        const productVariant = productMod.activeColorName ? productMod.activeColorName : productMod.activeVariant
        const sku = productMod.product.sku
        const brand_id = productMod.product.brand_id
        const brand = brands && brand_id !== 0 ? brands.brand.filter(obj => brand_id === obj.bigcommerce_id)[0] : "Attitude.se"
        const itemsArray = []
        // With Sizes
        if (Object.keys(productMod.activeSize).length > 0) Object.keys(productMod.activeSize).forEach(key => {
            const activeSize = productMod.activeSize[key]
            const activeSizeName = activeSize.label
            const quantity = activeSize.quantity
            itemsArray.push({
                item_id: productId,
                item_name: `${productName} - ${activeSizeName}`,
                sku: sku,
                affiliation: "Attitude.se",
                index: itemsArray.length + 1,
                // item_category: "Apparel",
                // item_category2: "Adult",
                // item_category3: "Shirts",
                // item_category4: "Crew",
                // item_category5: "Short sleeve",
                item_variant: productVariant,
                item_brand: brand.name,
                price: productPrice,
                quantity: quantity
            })
        })
        else itemsArray.push({
            item_id: productId,
            item_name: `${productName}`,
            sku: sku,
            affiliation: "Attitude.se",
            index: itemsArray.length + 1,
            // item_category: "Apparel",
            // item_category2: "Adult",
            // item_category3: "Shirts",
            // item_category4: "Crew",
            // item_category5: "Short sleeve",
            item_variant: productVariant,
            item_brand: brand.name,
            price: productPrice,
            quantity: productMod.quantity
        })
        return {
            currency: "SEK",
            value: productMod.totalPrice,
            items: itemsArray
        }
    }
    catch (err) { console.log(err) }
}

export { dataLayerPush, cartEcommerceObject, lineItemEcommerceObject, storeUserInformation }