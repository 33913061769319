import React, { createContext, useState, useEffect } from 'react';

// Fetch
import { uploadCartFile } from "../fetch/enquiry";

// Functions
import { floorValue } from '../functions/number-formatter';
import { createItemsObject } from '../functions/cart';
import { hardCopy } from '../functions/util';

const initialState = {
  cartLoading: false,
  cartError: false,
  quantity: 0,
  vatIncluded: false,
  vat: 1.25,
  vatExclAmount: 0.8,
  setup_fee_cost: 439,
  cart: {
    currency: {
      code: 'SEK'
    },
    totalAmount: 0,
    cartAmount: 0,
    shippingCost: 0,
    colorSetupCost: 0,
    lineItems: {
      physical_items: [],
      custom_items: [],
      gift_certificates: []
    },
    numberItems: 0,
    redirectUrls: {}
  },
  meta: {}
};

const CartContext = createContext(initialState);

export const CartProvider = ({ children }) => {
  const [state, setState] = useState(initialState);
  const [addingToCart, setAddingToCart] = useState(false);
  const [cartError, setCartError] = useState(false);
  const [notifications, updateNotifications] = useState([]);

  const addNotification = (text, type = 'notify') => updateNotifications([...notifications, { text, type, id: Date.now() }]);

  const removeNotification = id => updateNotifications(notifications.filter(ntfy => ntfy.id !== id));

  const toggleVatStatus = (value) => { setState({ ...state, vatIncluded: value }) };

  const fetchCart = async () => {
    return new Promise((resolve, reject) => {
      setState(initialState)
      // Get Cart Object
      try {
        fetch(`/.netlify/functions/bigcommerce?endpoint=carts`, {
          credentials: 'same-origin',
          mode: 'same-origin'
        })
          // Get Cart
          .then(response => refreshCart(response))
          // Construct Object
          .then(async (cartResponse) => {
            const cartObject = cartResponse ? cartResponse : {}
            const metaData = await fetchCartMeta()
            const metaObject = metaData ? metaData : {}
            return {
              ...cartObject,
              ...metaObject
            }
          })
          // Fix the pricing
          .then(obj => {
            if (obj?.cart) {
              // Color Setup
              let colorSetups = 0
              obj.meta && Object.keys(obj.meta).forEach(key => {
                colorSetups += obj.meta[key].colorSetups
              })
              // Calculates the Price
              obj.cart.cartAmount = floorValue(obj?.cart?.cartAmount, 0)
              obj.cart.colorSetupCost = colorSetups * state.setup_fee_cost > 0 ? colorSetups * state.setup_fee_cost : 0
              obj.cart.shippingCost = obj?.cart?.cartAmount + obj?.cart?.colorSetupCost >= 3000 ? 0 : 299
              obj.cart.totalAmount = floorValue(obj?.cart?.cartAmount + obj?.cart?.colorSetupCost + obj?.cart?.shippingCost, 0)
            }
            return obj
          })
          .then(obj => {
            // Set State
            setState({
              ...state,
              ...obj
            });
            // Return the State
            resolve({
              ...state,
              ...obj
            })
          })
          .catch(error => {
            console.log("Cart Error: ", error)
            setState({ ...state, cartLoading: false });
            resolve({})
          });
      }
      catch (error) {
        console.log("Cart Error: ", error)
        setState({ ...state, cartLoading: false });
        resolve({})
      }
    })
  };

  const fetchCartMeta = async () => {
    // Get Meta Object
    try {
      return await fetch(`/.netlify/functions/bigcommerce?endpoint=cart/meta`, {
        credentials: 'same-origin',
        mode: 'same-origin'
      })
        .then(response => {
          return refreshCartMeta(response);
        })
        .catch(error => {
          setState({ ...state, cartLoading: false });
        });
    }
    catch (error) {
      console.log("Cart Meta Error: ", error)
      setState({ ...state, cartLoading: false });
    }
  };

  // eslint-disable-next-line
  useEffect(() => { fetchCart() }, []);
  // Added to cart
  useEffect(() => {
    setTimeout(() => {
      if (addingToCart) setAddingToCart(false)
    }, 7000);
  }, [addingToCart]);
  // Cart error
  useEffect(() => {
    setTimeout(() => {
      if (cartError) setCartError(false)
    }, 10000);
  }, [cartError]);

  const refreshCart = async response => {
    if (response.status === 204 || response.status === 404) return true
    else if (response) {
      let payload
      try { payload = await response.json() }
      catch (err) { payload = await response }
      const lineItems = payload?.data?.line_items;
      const cartAmount = payload?.data?.cart_amount;
      const currency = payload?.data?.currency;
      let quantity = 0
      Object.keys(lineItems).forEach(key => {
        const lineItemType = lineItems[key]
        lineItemType.forEach(item => quantity += item.quantity)
      })
      return {
        quantity,
        cart: {
          currency,
          cartAmount,
          lineItems,
          numberItems:
            lineItems.physical_items.length +
            lineItems.digital_items.length +
            lineItems.custom_items.length +
            lineItems.gift_certificates.length,
          redirectUrls: payload.data.redirect_urls
        }
      }
    }
  };

  const refreshCartMeta = async response => {
    if (response.status === 204 || response.status === 404) setState(initialState)
    else if (response) {
      const payload = await response.json()
      const metaData = payload?.data;
      const metaDataObject = {}
      metaData.forEach(obj => {
        const meta = JSON.parse(obj.value)
        meta.metafield_id = obj.id
        metaDataObject[obj.key] = meta
      })
      return {
        meta: metaDataObject
      }
    }
  };

  const addToCart = (lineItemObject, files, retry, secResolved) => {
    return new Promise(async (resolve, reject) => {
      setAddingToCart("loading")
      const printId = lineItemObject.meta.id

      // Upload Files
      const uploadFiles = async () => {
        const fileUrls = []
        if (files.length > 0) await Promise.all(Array.from(files).map((file, i) => uploadCartFile(file, file.type, printId, i)))
          .then((url) => { fileUrls.push(url) })
        lineItemObject.meta.images = fileUrls[0] // It becomes a nested array of some reason
      }
      console.log("Line Item Object: ", lineItemObject)
      fetch(`/.netlify/functions/bigcommerce?endpoint=carts/items`, {
        method: 'POST',
        credentials: 'same-origin',
        mode: 'same-origin',
        body: JSON.stringify({
          line_items: lineItemObject.lineItems
        })
      })
        .then(async res => { return { response: res, status: res.status } })
        .then(async ({ response, status }) => {
          console.log("Response: ", response)
          const res = await response.json()
          console.log("Res: ", res)
          if (status === 404 && !retry) {
            await fetch(`/.netlify/functions/bigcommerce?endpoint=carts`, {
              credentials: 'same-origin',
              mode: 'same-origin'
            })
              .then(() => addToCart(lineItemObject, files, true, resolve))
          }
          else if (status === 409) {
            const res = await response.json()
            setCartError(res.title)
            throw res.title
          }
          else if (status === 422) {
            const res = await response.json()
            console.log(res)
            setCartError(res.title, res.error)
            throw res.title
          }
          else if (!response) {
            throw "No response"
          }
          else {
            await uploadFiles()
            await addMetaData(lineItemObject)
            await fetchCart()
            setAddingToCart("Tillagd i kundvagnen ")
            resolve()
            if (secResolved) secResolved()
          }
        })
        .catch(error => {
          console.log("Error: ", error)
          setAddingToCart(false)
          setCartError("Något gick fel! Kontakta oss på hej@attitude.se istället.")
          reject(error)
        });
    })
  };

  const addMetaData = (lineItemObject) => {
    return new Promise(async (resolve, reject) => {
      try {
        // Create Meta Body
        const metaBody = JSON.stringify({
          "permission_set": "read_and_sf_access",
          "namespace": "Enquiry",
          "key": lineItemObject.meta.id,
          "value": JSON.stringify(lineItemObject.meta),
          "description": "Enquiry Item Data"
        })
        await fetch(`/.netlify/functions/bigcommerce?endpoint=cart/meta`, {
          method: 'POST',
          credentials: 'same-origin',
          mode: 'same-origin',
          body: metaBody
        })
          .then(async res => ({ response: await res.json(), status: res.status }))
          .then(({ response, status }) => {
            if (status === 409) {
              setCartError(response.title)
              throw response
            }
            else if (status === 422) {
              setCartError(response.title, response.error[0])
              throw response
            }
            else if (!response) {
              throw "No response"
            }

            resolve()
          })
      }
      catch (error) {
        console.log("Error: ", error)
        setAddingToCart(false)
        setCartError(error?.message)
        reject()
      }
    })
  }

  const removeMetaData = (metafield_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        await fetch(`/.netlify/functions/bigcommerce?endpoint=cart/meta/delete&metafield_id=${metafield_id}`, {
          method: 'DELETE',
          credentials: 'same-origin',
          mode: 'same-origin'
        })
          .then(async res => ({ response: await res.text(), status: res.status }))
          .then(({ response, status }) => {
            if (status === 204) resolve()
            else if (status === 422) {
              setCartError(response, response)
              throw response
            }
          })
      }
      catch (error) {
        console.log("Error: ", error)
      }
    })
  }

  const deleteCart = () => {
    fetch(
      `/.netlify/functions/bigcommerce?endpoint=carts`,
      {
        credentials: 'same-origin',
        mode: 'same-origin',
        method: 'delete'
      }
    )
      .then(res => res)
      .then(() => clearCart())
      .catch(error => {
        clearCart()
        setCartError(error.message)
      });
  };

  const clearCart = () => {
    setState(initialState);
  };

  const updateItemInCart = (itemId, updatedItemData) => {
    fetch(
      `/.netlify/functions/bigcommerce?endpoint=carts/items&itemId=${itemId}`,
      {
        credentials: 'same-origin',
        mode: 'same-origin',
        method: 'put',
        body: JSON.stringify(updatedItemData)
      }
    )
      .then(res => res.json())
      .then(response => {
        refreshCart(response);
      })
      .catch(error => {
        setState({ ...state, cartLoading: false });
        setCartError(error.message)
      });
  };

  const removeItemFromCart = async (cartObject, uniqueId) => {
    return new Promise(async (resolve, reject) => {
      const itemsObject = createItemsObject(cartObject)
      // Get the IDs
      const printIdArray = itemsObject[uniqueId].prints.map(prints => prints.id)
      const sizeIdArray = itemsObject[uniqueId].sizes.map(size => size.id)
      const removeIds = [...printIdArray, ...sizeIdArray]
      // // Resets the Cart
      // const resetObject = hardCopy(initialState)
      // setState(initialState);
      // Loop through the IDs
      for (var i = 0; i < removeIds.length; i++) {
        const itemId = removeIds[i]
        const url = `/.netlify/functions/bigcommerce?endpoint=carts/items&itemId=${itemId}`
        await fetch(url,
          {
            credentials: 'same-origin',
            mode: 'same-origin',
            method: 'DELETE'
          }
        )
          .then(res => {
            if (res.status === 204) {
              setState(initialState);
              return;
            }
            return res;
          })
          .then(async (res) => {
            try {
              if (cartObject?.meta[uniqueId]?.metafield_id) await removeMetaData(cartObject.meta[uniqueId].metafield_id)
              return res
            }
            catch (err) { return res }
          })
          .then(async (res) => {
            if (i === (removeIds.length === 0 ? 0 : removeIds.length - 1) && res) await fetchCart();
            setAddingToCart("Borttagen från kundvagnen ")
            resolve()
          })
          .catch(error => {
            setState({ ...state, cartLoading: false });
            setCartError(error.message)
            resolve()
          });
      }
    })
  };

  const updateCartItemQuantity = (item, action) => {
    const newQuantity = item.quantity + (action === 'minus' ? -1 : 1);
    setState({ ...state, updatingItem: item.id });
    if (newQuantity < 1) {
      return removeItemFromCart(item.id);
    }
    let productVariantReferences = null;

    if (typeof item.product_id !== 'undefined') {
      productVariantReferences = {
        product_id: item.product_id,
        variant_id: item.variant_id
      };
    }

    updateItemInCart(item.id, {
      line_item: {
        quantity: newQuantity,
        ...productVariantReferences
      }
    });
  };

  return (
    <CartContext.Provider
      value={{
        state,
        fetchCart,
        fetchCartMeta,
        addToCart,
        deleteCart,
        clearCart,
        removeItemFromCart,
        removeMetaData,
        toggleVatStatus,
        updateCartItemQuantity,
        notifications,
        addNotification,
        removeNotification,
        addingToCart,
        cartError,
        setCartError
      }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
