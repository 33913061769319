import * as React from "react"

function Phone(props) {
  const { color, size, margin } = props
  return (
    <svg width={size + "pt"} height={size + "pt"} version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" style={{marginRight: margin}}>
      <g fill={color}>
        <path d="m1064.4 427.2c-26.398-66-66-124.8-116.4-175.2s-109.2-88.801-175.2-116.4c-32.398-13.199-66-22.801-99.602-30-12-2.3984-24 6-26.398 18-2.3984 12 6 24 18 26.398 31.199 6 62.398 14.398 91.199 26.398 60 24 114 60 159.6 106.8 45.602 45.602 81.602 99.602 106.8 159.6 12 30 21.602 60 26.398 91.199 2.3984 10.801 10.801 18 21.602 18h3.6016c12-2.3984 20.398-14.398 18-26.398-4.8047-32.402-14.402-66-27.605-98.402z" />
        <path d="m884.4 499.2c9.6016 24 16.801 48 20.398 73.199 1.1992 10.801 10.801 19.199 21.602 19.199h3.6016c12-2.3984 20.398-13.199 18-25.199-4.8008-28.801-12-56.398-22.801-84-19.199-46.801-46.801-90-82.801-126s-78-64.801-126-82.801c-26.398-10.801-55.199-19.199-82.801-22.801-12-2.3984-24 6-25.199 18-1.1992 12 6 24 18 25.199 25.199 3.6016 50.398 10.801 73.199 20.398 42 16.801 79.199 42 110.4 73.199 31.203 31.207 57.605 70.805 74.402 111.61z" />
        <path d="m568.8 416.4c-1.1992 12 8.3984 22.801 20.398 24 44.398 3.6016 85.199 22.801 116.4 54 31.199 31.199 50.398 72 54 116.4 1.1992 12 10.801 20.398 22.801 20.398h2.3984c12-1.1992 21.602-12 20.398-24-4.8008-54-27.602-105.6-67.199-144-38.398-38.398-90-62.398-144-67.199-13.195-1.1953-23.996 7.2031-25.195 20.402z" />
        <path d="m1052.4 829.2c-16.801-13.199-37.199-22.801-55.199-30-58.801-24-118.8-39.602-170.4-51.602-18-3.6016-43.199-10.801-68.398-1.1992-19.199 7.1992-31.199 21.602-42 34.801-3.6016 4.8008-8.3984 9.6016-12 14.398-9.6016 9.6016-36 4.8008-68.398-13.199-37.199-19.199-81.602-54-123.6-96-40.801-40.801-76.801-86.398-96-123.6-18-32.398-22.801-58.801-13.199-68.398 3.6016-3.6016 8.3984-7.1992 14.398-12 13.199-10.801 27.602-22.801 34.801-42 9.6016-25.199 3.6016-51.602-1.1992-68.398-12-51.602-27.602-111.6-51.602-170.4-7.1992-19.199-16.801-38.398-30-55.199-16.801-20.398-37.199-34.801-60-38.398-31.199-6-66 6-100.8 36-26.398 22.801-49.199 50.398-66 82.801-16.801 31.199-28.801 64.801-33.602 100.8-12 81.602 8.3984 171.6 63.602 276 46.801 90 106.8 172.8 178.8 244.8 72 70.801 154.8 130.8 244.8 178.8 85.199 44.398 160.8 67.199 230.4 67.199 15.602 0 31.199-1.1992 45.602-3.6016 34.801-4.8008 68.398-16.801 100.8-33.602 31.199-16.801 58.801-39.602 82.801-66 30-33.602 42-68.398 36-100.8-4.8047-22.801-19.203-44.402-39.605-61.203z" />
      </g>
    </svg>
  )
}

export default Phone
