import * as React from "react"

function Sweden(props) {
  const { size, margin } = props
  return (
    <svg width={size + "pt"} height={size + "pt"} version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: margin }} alt="Sweden icon">
      <g fill="#005293">
        <path fill="yellow" d="M 596.545 283.808 L 603.382 1083.757 L 565.426 1008.837 C 565.426 1008.837 502.085 946.333 502.286 946.333 C 502.487 946.333 425.364 873.121 425.364 873.121 L 311.022 784.205 L 241.808 723.335 L 161.33 635.109 L 111.717 561.563 L 75.69 464.879 C 75.69 464.879 65.565 391.795 65.565 391.587 C 65.565 391.379 84.837 288.435 84.837 288.435 L 133.179 218.786 L 195.342 172.895 L 290.987 129.583 L 394.118 121.527 L 498.214 154.46 L 543.147 193.426 L 596.545 283.808 Z" />
        <path fill="yellow" d="M 597.127 287.002 L 596.672 1074.884 L 660.054 992.15 L 749.31 902.317 L 887.036 788.103 L 982.051 703.547 L 1062.1 603.77 L 1113.067 512.652 L 1133.859 421.232 L 1116.281 303.73 L 1085.183 248.263 L 978.509 154.741 L 884.612 120.047 L 700.038 171.141 L 597.127 287.002 Z" />
        <path d="m394.55 119.5c-165.14-23.699-353.11 104.66-328.81 309.28h328.82v-309.28z" />
        <path d="m156.76 634.25c63.336 79.02 154.01 150.37 237.79 219.61l-0.003906-219.61z" />
        <path d="m595.14 634.25v449.15h0.023437c54.926-150.45 309.97-282.79 443.26-449.15z" />
        <path d="m1129.5 428.76c36.852-310.56-415.41-445.64-534.32-139.6v139.6z" />
        <path d="m1100.3 252.39c-52.5-84.969-153.45-139.86-257.16-139.86-109.15 0-197.12 57.539-243.13 158.4-45.996-100.86-133.96-158.4-243.13-158.4-103.69 0-204.64 54.887-257.14 139.86-53.879 87.18-52.918 196.44 2.6641 307.64 51.539 103.07 158.26 190.74 261.48 275.53 101.75 83.629 197.88 162.64 228.52 246.59 1.1641 3.1914 4.2227 5.3047 7.6094 5.3047h0.023438c3.3594 0 6.4062-2.125 7.5977-5.3047 30.637-83.902 126.76-162.91 228.52-246.56 103.25-84.816 209.98-172.55 261.47-275.55 55.617-111.25 56.578-220.5 2.6875-307.65zm-743.41-123.66c108.76 0 194.63 60.504 235.57 165.96 2.3398 6.1211 12.816 6.0586 15.109 0.023438 40.957-105.48 126.83-165.98 235.59-165.98 98.207 0 193.74 51.887 243.35 132.19 50.832 82.246 49.645 185.88-3.3477 291.86-50.016 100.03-155.39 186.56-257.36 270.36-96.395 79.223-187.67 154.22-225.76 235.25-38.09-81.047-129.38-156.1-225.84-235.36-101.93-83.746-207.3-170.34-257.27-270.25-52.992-106-54.191-209.65-3.3711-291.86 49.609-80.309 145.14-132.19 243.34-132.19z" />
      </g>
    </svg >
  )
}

export default Sweden
