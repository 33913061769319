import * as React from "react"

function Quality(props) {
    const { color, size } = props
    return (
        <svg width={size + "pt"} height={size + "pt"} version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" alt="Quality icon">
            <g fill={color}>
                <path d="m337.96 377.14-137.14 251.02 399.18 438.37 399.18-439.59-137.14-249.8zm475.1 48.977-67.348 167.75-105.3-167.75zm-122.45 171.43h-182.45l90.613-145.71zm-132.25-171.43-104.08 166.53-66.121-166.53zm-209.39 33.062 55.102 138.37h-131.02zm-62.449 187.35h137.14l105.3 264.49zm313.47 318.37-123.67-313.47 1.2227-4.8984h243.67l1.2227 4.8984 1.2227 1.2227zm72.246-52.652 105.3-264.49h135.92zm124.9-314.7 55.102-137.14 75.918 137.14z" />
                <path d="m575.51 132.25h48.98v159.18h-48.98z" />
                <path d="m1076.4 226.23 33.914 35.34-124.57 119.55-33.914-35.34z" />
                <path d="m123.05 226.29 124.57 119.56-33.918 35.34-124.57-119.56z" />
            </g>
        </svg>

    )
}

export default Quality
