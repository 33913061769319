import React, { createContext, useState } from 'react';

import uniqueId from '../functions/unique-id';

const PriceContext = createContext();

export const PriceProvider = ({ children }) => {

  const [prices, setPrices] = useState(false)

  const calculatePrice = (lineItemArray) => {
    setPrices(false)
    fetch(`/.netlify/functions/bigcommerce?endpoint=pricing/products`, {
      method: 'POST',
      credentials: 'same-origin',
      mode: 'same-origin',
      body: JSON.stringify({
        "channel_id": 1,
        "currency_code": "SEK",
        "customer_group_id": 0,
        "items": lineItemArray
      })
    })
      .then(res => res.json())
      .then(response => {
        console.log(response.data)
        setPrices(response.data)
      })
      .catch(error => {
        console.error(error);
        setPrices({ error });
      });
  };

  return (
    <PriceContext.Provider value={{
      prices,
      calculatePrice
    }}>{children}</PriceContext.Provider>
  );
};

export default PriceContext;