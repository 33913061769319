import React from 'react';
import Notify from '../bigcommerce/Notify';
import BreadCrumb from './breadcrumb';
import Footer from './footer';

const Layout = ({ children, location, crumbLabel }) => {
  return (
    <>
      <Notify />
      {location ? <BreadCrumb location={location} crumbLabel={crumbLabel} /> : null}
      {children}
      <Footer />
    </>
  );
};

export default Layout;
