function numberWithCommas(x) {
    try { return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); }
    catch (err) { return x }
}

function floorValue(value, decimals) {
    try { return Number(Number(value).toFixed(decimals)) }
    catch (err) { return Math.floor(value) }
}

export { numberWithCommas, floorValue }