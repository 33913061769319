import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyBhm2f4M8femRxSs8rgkHnYTXWJSTZvCpg",
    authDomain: "attitude-firestore.firebaseapp.com",
    projectId: "attitude-firestore",
    storageBucket: "attitude-cart-files",
    messagingSenderId: "287803462284",
    appId: "1:287803462284:web:3789c28fbc2d91473961a9"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage();

// Get a list of cities from your database
export default function getEnquiries(id) {
    return new Promise(async (resolve, reject) => {
        try {
            const enquiryRef = doc(db, "enquiry", id);
            const enquirySnap = await getDoc(enquiryRef);
            if (enquirySnap.exists()) {
                resolve(enquirySnap.data())
            } else {
                reject("No enquiry found")
            }
        }
        catch (err) { reject(err) }
    })
}

const uploadCartFile = async (file, fileType, printId, fileIndex) => {
    return new Promise((resolve, reject) => {
        try {
            // Create the file metadata
            /** @type {any} */
            const metadata = {
                "contentType": fileType,
                "customMetadata": {
                    "printId": printId,
                    "timestamp": new Date().toString()
                }
            };
            
            const fileName = `${printId}_file_${fileIndex}`
            const storageRef = ref(storage, `${fileName}`);
            const uploadTask = uploadBytesResumable(storageRef, file, metadata);

            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    //console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            //console.log('Upload is paused');
                            break;
                        case 'running':
                            //console.log('Upload is running');
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            reject("User dont have access / Authentication problem")
                            break;
                        case 'storage/canceled':
                            reject("User cancelled upload")
                            break;
                        case 'storage/unknown':
                            reject("Unknown upload problem")
                            break;
                        default:
                            break;
                    }
                },
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        resolve({ name: fileName, url: downloadURL })
                    });
                }
            );
        } catch (err) {
            console.error(err);
            reject()
        }
    })
};

export { db, uploadCartFile }