import * as React from "react"

function VolumeReq(props) {
  const { color, size } = props
  return (
    <svg width={size + "pt"} height={size + "pt"} version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" alt="Volume Requirement icon">
      <g fill={color ? color : "#d1514c"}>
        <path d="m71.988 351.48c-7.1328-7.1328-17.531-9.918-27.277-7.3086-9.7422 2.6094-17.355 10.219-19.969 19.965-2.6094 9.7422 0.17578 20.141 7.3086 27.277l123.37 123.38c7.1367 7.1133 17.523 9.8867 27.258 7.2734 9.7305-2.6133 17.336-10.215 19.949-19.945 2.6133-9.7344-0.15625-20.121-7.2695-27.258z" />
        <path d="m809.09 771.11c-18.914 16.082-42.93 24.91-67.758 24.91-24.824 0-48.844-8.8281-67.758-24.91l-190.31-160.95c-6.8516-5.832-15.555-9.0352-24.551-9.0352-8.9961 0-17.699 3.2031-24.551 9.0352l-156.09 132c-0.16797 32.289-0.83594 65.75-2.3438 100.3l115.19-97.371c18.918-16.078 42.934-24.906 67.758-24.906 24.828 0 48.844 8.8281 67.758 24.906l190.31 160.95c6.8672 5.832 15.586 9.0352 24.594 9.0352 9.0117 0 17.727-3.2031 24.594-9.0352l156.43-132.34c-0.58594-34.047-0.55469-66.836 0.085938-98.375z" />
        <path d="m925.03 859.12-115.94 98.039c-18.914 16.078-42.93 24.906-67.758 24.906-24.824 0-48.844-8.8281-67.758-24.906l-190.31-160.95c-6.8516-5.832-15.555-9.0352-24.551-9.0352-8.9961 0-17.699 3.2031-24.551 9.0352l-163.54 138.27v0.085937c-4.4336 61.484-11.043 125.89-20.41 193.15-1.0039 7.2188 0.96484 14.539 5.4609 20.281 4.4961 5.7383 11.125 9.4102 18.379 10.168l226.32 24.16c66.262 7.0703 133.09 7.0703 199.35 0l226.32-24.16c7.2539-0.75781 13.883-4.4297 18.379-10.168 4.4961-5.7422 6.4648-13.062 5.4609-20.281-13.301-95.363-21.078-185.21-24.844-268.61z" />
        <path d="m1138.3 293.63c-81.145-192.82-173.66-222.43-173.66-222.43-37.559-14.973-71.102-28.023-103.64-39.23v-0.003906c1.2773 18.164-4.1406 36.168-15.223 50.613l-78.301 102.97c-26.277 34.516-62.57 60.066-103.93 73.16-41.352 13.098-85.738 13.098-127.09 0-41.352-13.094-77.648-38.645-103.92-73.16l-78.297-103.06c-11.098-14.406-16.516-32.387-15.227-50.527-32.539 11.211-66.086 24.262-103.64 39.234 0 0-92.52 29.613-173.66 222.43 17.32 2.1211 33.43 9.9844 45.758 22.336l117.36 117.36c8.7656-21.492 19.934-41.918 33.293-60.898 8.0312 75.539 16.48 170.73 19.156 282.74l113.6-96.117c18.934-16.039 42.945-24.844 67.758-24.844 24.816 0 48.824 8.8047 67.758 24.844l190.31 160.95c6.8672 5.832 15.586 9.0312 24.594 9.0312 9.0117 0 17.727-3.1992 24.594-9.0312l159.27-134.76c3.8477-82.145 10.457-153.5 16.73-212.81 13.359 18.977 24.527 39.406 33.293 60.898l117.36-117.36c12.328-12.352 28.438-20.215 45.758-22.336z" />
        <path d="m1167.9 351.48c-5.293-5.293-12.477-8.2695-19.965-8.2695s-14.672 2.9766-19.969 8.2695l-123.37 123.38c-5.2969 5.2969-8.2695 12.477-8.2695 19.969 0 7.4883 2.9766 14.668 8.2734 19.965 5.293 5.293 12.477 8.2695 19.965 8.2695 7.4922 0 14.672-2.9766 19.969-8.2734l123.37-123.38c5.2969-5.2969 8.2734-12.48 8.2695-19.969 0-7.4883-2.9766-14.668-8.2734-19.965z" />
        <path d="m472.52 155.18c30.305 39.797 77.461 63.16 127.48 63.16s97.176-23.363 127.48-63.16l78.375-103.04c5.0938-6.6953 6.4414-15.52 3.5742-23.426-2.8633-7.9102-9.5508-13.824-17.75-15.703-4.8477-1.1094-9.9102-0.80078-14.586 0.89453-4.6797 1.6914-8.7656 4.6992-11.777 8.6562l-77.738 102.17c-20.465 27.109-52.383 43.156-86.352 43.406-35.863-0.19922-69.535-17.285-90.871-46.113l-75.676-99.461c-3.0117-3.957-7.1016-6.9648-11.777-8.6562-4.6758-1.6953-9.7422-2.0039-14.59-0.89453-8.1992 1.8789-14.887 7.793-17.75 15.703-2.8672 7.9062-1.5195 16.73 3.5703 23.426z" />
      </g>
    </svg>

  )
}

export default VolumeReq
