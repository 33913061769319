export default function gateway(data) {
  return new Promise(async (resolve, reject) => {
    const headers = new Headers({
      "Access-Control-Allow-Origin": "*"
    })
    const request = new Request(
      "https://europe-central2-attitude-firestore.cloudfunctions.net/attitude-gateway",
      {
        method: "POST",
        headers: headers,
        mode: "cors",
        cache: "default",
        body: JSON.stringify(data),
      }
    )

    await fetch(request)
      .then(async response => {
        if (response.status !== 200) throw await response.text()
        else return response
      })
      .then(response => response.json())
      .then(data => {
        console.log("Success. Data: ", data)
        resolve(data)
      })
      .catch(error => {
        console.error("Error:", error)
        reject(error)
      })
  })
}
