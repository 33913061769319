import * as React from "react"

function StartFee(props) {
  const { color, size } = props
  return (
    <svg width={size + "pt"} height={size + "pt"} version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" alt="Start fee icon">
      <path d="m1080 200v-133.33h-413.33v133.33h-133.34v-133.33h-413.33v133.33h-120v400h120v146.68h133.33v120h133.33v146.66h133.33v120h160v-120h133.32v-146.66h133.33v-120h133.36v-146.68h120v-400m-906.67 148.19v145.15h-160v-280.01h160z" fill={color ? color : "#d1514c"} />
    </svg>
  )
}

export default StartFee
