import * as React from "react"

import Delimiter from "./delimiter"

import Delivery from "../../icons/delivery"
import VolumeReq from "../../icons/volume-req"
import StartFee from "../../icons/start-fee"
import Sweden from "../../icons/sweden"
import Quality from "../../icons/quality"
import Discount from "../../icons/discount"

import "../../styles/content/usps.scss"

const USP = ({ dark, vertical }) => {
    const classes = () => {
        let classNames = "boxWrapper"
        if (dark) classNames += " dark"
        if (vertical) classNames += " vertical"
        return classNames
    }
    return (
        <div id="usps">
            <Delimiter text={"Varför du skall välja Attitude.se"} />
            <div className={classes()}>
                <div className="uspBox">
                    <div className="usp">
                        <div className="icon"><Quality color={"var(--attitude-green)"} size={"45"} /></div>
                        <div className="text">
                            <h3>Kvalitetssäkring</h3>
                            <p>Du godkänner alltid korrektur, gjord av en grafiker, innan produktion.</p>
                        </div>
                    </div>
                    <div className="usp">

                        <div className="icon"><VolumeReq color={"var(--attitude-green)"} size={"30"} /></div>
                        <div className="text">
                            <h3>Låga volymkrav</h3>
                            <p>Flera av våra artiklar har 1 artikel som minsta beställningsantal.</p>
                        </div>
                    </div>
                    <div className="usp">
                        <div className="icon"><StartFee color={"#B01E28"} size={"30"} /></div>
                        <div className="text">
                            <h3>Inga startavgifter</h3>
                            <p>I vår prissättning tillkommer inga startavgifter.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes()}>
                <div className="uspBox">
                    <div className="usp">
                        <div className="icon"><Sweden size={"40"} /></div>

                        <div className="text">
                            <h3>Kläder trycks i Sverige</h3>
                            <p>Flera av våra kläder trycks i Sverige med hög kvalitet & låga felmarginaler.</p>
                        </div>
                    </div>

                    <div className="usp">

                        <div className="icon"><Delivery color={"var(--attitude-green)"} size={"50"} /></div>
                        <div className="text">
                            <h3>Fri frakt över 3000kr</h3>
                            <p>Leveranstid är ungefär 2 veckor men prata med oss om det är brådskande.</p>
                        </div>
                    </div>
                    <div className="usp">
                        <div className="icon"><Discount color={"var(--attitude-green)"} size={"35"} /></div>

                        <div className="text">
                            <h3>Mängdrabatt</h3>
                            <p>Större antal artiklar per order ger dig lägre pris per artikel.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default USP