import * as React from "react"

function Email(props) {
  const { color, size, margin } = props
  return (
    <svg width={size + "pt"} height={size + "pt"} version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" style={{marginRight: margin}}>
      <path d="m335.5 403.52c-5.9414 0-11.578 1.2461-16.766 3.3047l263.08 223.88c11.516 9.8086 24.355 9.8164 35.895 0l263.32-224.11c-5.1133-1.9883-10.695-3.0703-16.531-3.0703zm-44.871 38.73c-0.30469 2.1406-0.46875 4.3867-0.46875 6.6133v302.29c0 25.121 20.223 45.344 45.344 45.344h529c25.121 0 45.344-20.223 45.344-45.344v-302.29c0-2.2266-0.16406-4.4727-0.47266-6.6133l-262.14 222.94c-27.176 23.109-67.766 23.145-94.938 0l-261.66-222.94z" fill={color} />
    </svg>
  )
}

export default Email
