import * as React from "react"

import "../../styles/content/delimiter.scss"

const Delimiter = ({ text }) => {
    return (
        <>
            <div id="delimiter">
                <div className="box">
                    <p>{text}</p>
                </div>
            </div>
        </>
    )
}

export default Delimiter