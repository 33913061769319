import * as React from "react"
import "../styles/toast.scss"

export function Toast({ show, error, duration = 1000, className, ...props }) {
  const [visible, setVisible] = React.useState(show)
  const [animation, setAnimation] = React.useState("")

  React.useEffect(() => {
    if (show) {
      setVisible(true)
    }
    const timeout = setTimeout(() => {
      setAnimation("")
      setVisible(show)
    }, duration)
    setAnimation(show ? "showing" : "hiding")
    return () => clearTimeout(timeout)
  }, [show, duration])

  return visible ? (
    <div
    id="toast"
      className={[animation, className].join(" ")}
      style={{
        background: error ? "var(--attitude-red)" : "", 
        color: error ? "var(--white)" : ""}}
      {...props}
    />
  ) : null
}
