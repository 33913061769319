import * as React from "react"

const Menu = (props) => {
    const { color, size } = props
    return (
        <svg version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" alt="Menu icon">
            <path d="m280.24 380.84c-6.0117 0.3125-11.656 3-15.688 7.4766-4.0312 4.4727-6.1172 10.363-5.8047 16.375 0.3125 6.0156 3.0039 11.656 7.4766 15.688s10.367 6.1172 16.379 5.8047h634.8c6.0664 0.085938 11.918-2.2656 16.238-6.5273 4.3242-4.2578 6.7539-10.074 6.7539-16.145s-2.4297-11.883-6.7539-16.145c-4.3203-4.2617-10.172-6.6133-16.238-6.5273h-634.8c-0.78516-0.039062-1.5742-0.039062-2.3633 0zm0 196.49c-6.0117 0.30859-11.656 3-15.688 7.4727-4.0312 4.4727-6.1172 10.363-5.8047 16.379 0.3125 6.0117 3.0039 11.656 7.4766 15.688 4.4727 4.0273 10.367 6.1172 16.379 5.8008h634.8c6.0664 0.085937 11.918-2.2617 16.238-6.5234 4.3242-4.2617 6.7539-10.078 6.7539-16.148 0-6.0664-2.4297-11.883-6.7539-16.145-4.3203-4.2617-10.172-6.6094-16.238-6.5234h-634.8c-0.78516-0.042969-1.5742-0.042969-2.3633 0zm0 196.48c-6.0117 0.3125-11.656 3-15.688 7.4727-4.0312 4.4727-6.1172 10.367-5.8047 16.379 0.3125 6.0156 3.0039 11.656 7.4766 15.688s10.367 6.1172 16.379 5.8047h634.8c6.0664 0.085938 11.918-2.2656 16.238-6.5273 4.3242-4.2617 6.7539-10.078 6.7539-16.145 0-6.0703-2.4297-11.887-6.7539-16.148-4.3203-4.2578-10.172-6.6094-16.238-6.5234h-634.8c-0.78516-0.042968-1.5742-0.042968-2.3633 0z" fill={color ? color : "#fff"} />
        </svg>)
}

export default Menu