exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakta-oss-js": () => import("./../../../src/pages/kontakta-oss.js" /* webpackChunkName: "component---src-pages-kontakta-oss-js" */),
  "component---src-pages-order-add-js": () => import("./../../../src/pages/order/add.js" /* webpackChunkName: "component---src-pages-order-add-js" */),
  "component---src-pages-order-confirm-js": () => import("./../../../src/pages/order-confirm.js" /* webpackChunkName: "component---src-pages-order-confirm-js" */),
  "component---src-pages-personlig-service-js": () => import("./../../../src/pages/personlig-service.js" /* webpackChunkName: "component---src-pages-personlig-service-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-status-js": () => import("./../../../src/pages/status.js" /* webpackChunkName: "component---src-pages-status-js" */),
  "component---src-pages-tryck-pa-eget-plagg-js": () => import("./../../../src/pages/tryck-pa-eget-plagg.js" /* webpackChunkName: "component---src-pages-tryck-pa-eget-plagg-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/category-page.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-letter-page-js": () => import("./../../../src/templates/letter-page.js" /* webpackChunkName: "component---src-templates-letter-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */)
}

