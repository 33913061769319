import * as React from "react"

function Discount(props) {
  const { color, size } = props
  return (
    <svg width={size + "pt"} height={size + "pt"} version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" alt="Rabatt icon">
      <g fill={color}>
        <path d="m739.71 620.55c-22.5 0-32.281 25.453-32.281 57.254-0.49219 33.266 10.766 57.254 32.281 57.254 21.07 0 31.32-21.539 31.32-57.254 0-32.293-8.3047-57.254-31.32-57.254z" />
        <path d="m461.26 463.96c-22.02 0-32.281 25.441-32.281 57.254 0 33.277 11.258 57.254 32.773 57.254 21.035 0 31.332-21.516 31.332-57.254-0.003906-32.297-8.8359-57.254-31.824-57.254z" />
        <path d="m1087.6 644.48c-19.223-24.469-19.223-64.5 0-88.969l29.062-36.949c19.234-24.469 12.098-55.691-15.84-69.383l-42.215-20.699c-27.938-13.691-45.301-49.766-38.605-80.16l10.152-45.875c6.7188-30.395-13.246-55.43-44.363-55.645l-47.016-0.32422c-31.105-0.22656-62.41-25.188-69.539-55.477l-10.766-45.742c-7.1406-30.289-36-44.195-64.141-30.875l-42.469 20.102c-28.152 13.297-67.176 4.3906-86.746-19.801l-29.555-36.527c-19.586-24.191-51.602-24.191-71.184 0l-29.555 36.527c-19.586 24.191-58.621 33.109-86.746 19.801l-42.48-20.102c-28.129-13.309-57 0.58984-64.129 30.875l-10.766 45.742c-7.1406 30.289-38.449 55.246-69.562 55.477l-46.992 0.32422c-31.129 0.21484-51.098 25.246-44.375 55.645l10.152 45.875c6.707 30.395-10.668 66.469-38.605 80.16l-42.203 20.699c-27.949 13.691-35.09 44.93-15.84 69.383l29.039 36.949c19.234 24.469 19.234 64.5 0 88.969l-29.039 36.949c-19.246 24.469-12.109 55.691 15.84 69.383l42.203 20.699c27.938 13.691 45.301 49.754 38.605 80.16l-10.152 45.875c-6.7188 30.395 13.246 55.43 44.375 55.656l46.992 0.3125c31.117 0.22656 62.426 25.188 69.562 55.477l10.766 45.742c7.1289 30.289 36 44.207 64.129 30.875l42.48-20.102c28.129-13.285 67.164-4.3906 86.746 19.801l29.555 36.516c19.586 24.203 51.602 24.203 71.184 0l29.555-36.516c19.57-24.203 58.621-33.121 86.746-19.801l42.469 20.102c28.141 13.309 57-0.58984 64.141-30.875l10.766-45.742c7.1289-30.289 38.438-55.246 69.539-55.477l47.016-0.3125c31.129-0.22656 51.086-25.246 44.363-55.656l-10.152-45.875c-6.7305-30.395 10.668-66.469 38.605-80.16l42.215-20.699c27.938-13.703 35.074-44.93 15.84-69.383zm-713.98-121.32c0-56.762 34.754-99.828 89.062-99.828 54.805 0 85.633 40.117 85.633 94.453 0 66.551-42.07 101.29-88.078 101.29-48.469-0.003906-86.617-36.207-86.617-95.918zm166.87 253.51h-64.105l182.52-353.34h64.598zm197.71-0.98438c-47.977 0-86.137-36.215-86.605-95.902 0-56.762 34.727-99.828 89.039-99.828 54.816 0 85.645 40.129 85.645 94.453 0 66.539-42.086 101.28-88.078 101.28z" />
      </g>
    </svg>
  )
}

export default Discount
