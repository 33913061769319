import React from 'react';
import { Link } from 'gatsby';

// Components
import { CartSummary } from '../cart/cart-summary';

// Functions
import { createItemsObject } from '../../functions/cart';

// Icons
import Plus from "../../icons/plus"

// Context
import CartContext from '../../context/CartProvider';

import "../../styles/cart.scss"

const Cart = ({ cartType }) => {
  const cartContext = React.useContext(CartContext);
  const cartObject = cartContext && cartContext?.state
  const removeItemFromCart = cartContext && cartContext?.removeItemFromCart;
  const { cart } = cartObject ? cartObject : { currency: false, cartAmount: false, lineItems: false }

  // Sort items into Main Item and Sizes
  let itemsObject = React.useMemo(() => createItemsObject(cartObject), [cart])

  const removeItem = (uniqueId) => {
    let confirmAction = window.confirm("Vill du ta bort produkten?");
    if (confirmAction && itemsObject[uniqueId]?.sizes?.length > 0) removeItemFromCart(cartObject, uniqueId)
  }

  return (
    <>
      <div id="CartItems">
        <CartSummary itemsObject={itemsObject} enquiry={cartObject} />
        <div className="itemPicker">
          <div className="header">
            <p><b>Artiklar i varukorgen</b></p>
          </div>
          <div className="itemPickedWrapper">
            {Object.keys(itemsObject).map(key => {
              const item = itemsObject[key]
              return <div key={item.id} className="item">
                <button className="delete" onClick={() => removeItem(key)}>x</button>
                <img src={item.image_url} alt="Product" />
              </div>
            })}
            {cartType === "no-add" ? null : <Link to="/" title={"Gå till Startsidan"}>
              <div className="item add">
                <Plus size="40" color="var(--attitude-black)" />
              </div>
            </Link>}
          </div>
        </div>
      </div>
    </>
  )
}

export default Cart;
